var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Контакты и ссылки")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('ValidationObserver',{ref:"validation-observer",attrs:{"immediate":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","size":"md","placeholder":"Введите"},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Номер телефона","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","size":"md","placeholder":"Введите"},model:{value:(_vm.item.phone),callback:function ($$v) {_vm.$set(_vm.item, "phone", $$v)},expression:"item.phone"}})],1),(_vm.errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(_vm.errors[0])+" ")]):_vm._e()],1),_vm._l((_vm.item.address),function(data,key){return _c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":("Адрес [" + (key.toUpperCase()) + "]"),"label-for":("address-" + key)}},[_c('b-form-input',{attrs:{"id":("address-" + key),"size":"md","placeholder":"Введите"},model:{value:(_vm.item.address[key]),callback:function ($$v) {_vm.$set(_vm.item.address, key, $$v)},expression:"item.address[key]"}})],1),(_vm.errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(_vm.errors[0])+" ")]):_vm._e()],1)}),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Ссылка на Play Market","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ссылка на Play Market","label-for":"links_android"}},[_c('b-form-input',{attrs:{"id":"links_android","size":"md","placeholder":"Введите"},model:{value:(_vm.item.links.android),callback:function ($$v) {_vm.$set(_vm.item.links, "android", $$v)},expression:"item.links.android"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Ссылка на AppStore","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ссылка на AppStore","label-for":"links_ios"}},[_c('b-form-input',{attrs:{"id":"links_ios","size":"md","placeholder":"Введите"},model:{value:(_vm.item.links.ios),callback:function ($$v) {_vm.$set(_vm.item.links, "ios", $$v)},expression:"item.links.ios"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Онлайн чат | Служба поддержки","label-for":"online_chat"}},[_c('b-form-input',{attrs:{"id":"online_chat","size":"md","placeholder":"Введите"},model:{value:(_vm.item.online_chat),callback:function ($$v) {_vm.$set(_vm.item, "online_chat", $$v)},expression:"item.online_chat"}})],1),(_vm.errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(_vm.errors[0])+" ")]):_vm._e()],1),_vm._l((_vm.public_offer),function(data,key){return _c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":("Публичная оферта [" + (key.toUpperCase()) + "]"),"rules":"mimes:application/pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":("Публичная оферта [" + (key.toUpperCase()) + "]"),"label-for":("public-offer-" + key)}},[_c('b-form-file',{attrs:{"placeholder":"Загрузите pdf файл","drop-placeholder":"Drop file here...","id":("public-offer-" + key),"accept":"application/pdf"},model:{value:(_vm.public_offer[key]),callback:function ($$v) {_vm.$set(_vm.public_offer, key, $$v)},expression:"public_offer[key]"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)}),_vm._l((_vm.personal_data_agreement),function(data,key){return _c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":("Соглашение на обработку персональных данных [" + (key.toUpperCase()) + "]"),"rules":"mimes:application/pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":("Соглашение на обработку персональных данных [" + (key.toUpperCase()) + "]"),"label-for":("personal_data_agreement-" + key)}},[_c('b-form-file',{attrs:{"placeholder":"Загрузите pdf файл","drop-placeholder":"Drop file here...","id":("personal_data_agreement-" + key),"accept":"application/pdf"},model:{value:(_vm.personal_data_agreement[key]),callback:function ($$v) {_vm.$set(_vm.personal_data_agreement, key, $$v)},expression:"personal_data_agreement[key]"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)}),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Telegram"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telegram","label-for":"social-telegram"}},[_c('b-form-input',{attrs:{"id":"social-telegram","size":"md","placeholder":"Введите"},model:{value:(_vm.item.social.telegram),callback:function ($$v) {_vm.$set(_vm.item.social, "telegram", $$v)},expression:"item.social.telegram"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Facebook"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Facebook","label-for":"social-facebook"}},[_c('b-form-input',{attrs:{"id":"social-facebook","size":"md","placeholder":"Введите"},model:{value:(_vm.item.social.facebook),callback:function ($$v) {_vm.$set(_vm.item.social, "facebook", $$v)},expression:"item.social.facebook"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Instagram"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Instagram","label-for":"social-instagram"}},[_c('b-form-input',{attrs:{"id":"social-instagram","size":"md","placeholder":"Введите"},model:{value:(_vm.item.social.instagram),callback:function ($$v) {_vm.$set(_vm.item.social, "instagram", $$v)},expression:"item.social.instagram"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"YouTube"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"YouTube","label-for":"social-youtube"}},[_c('b-form-input',{attrs:{"id":"social-youtube","size":"md","placeholder":"Введите"},model:{value:(_vm.item.social.youtube),callback:function ($$v) {_vm.$set(_vm.item.social, "youtube", $$v)},expression:"item.social.youtube"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],2)],1),_c('b-button',{staticClass:"btn-success float-right",on:{"click":function($event){return _vm.update()}}},[_vm._v(" Сохранить ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }